import { Deletable, Identifiable } from 'shared/util/types.util'
import { filterDestructible } from 'components_shared/helpers/utils'
import ApiError from 'shared/redux/ApiError'
import { FieldValues, UseFormSetError } from 'react-hook-form'
import type { MutationDefinition } from '@reduxjs/toolkit/src/query/endpointDefinitions'
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate'

export function mergeCollection<T extends Deletable & Identifiable, S>(
  initialItems: T[], newState: S[], mapper: (t: S) => T,
): T[] {
  const newItems = newState.map(mapper)
  return [
    ...newItems,
    ...destroyedInitialItems(initialItems, newItems),
  ]
}

function destroyedInitialItems<T extends Deletable & Identifiable>(initialItems: T[], newItems: T[]): T[] {
  return filterDestructible(initialItems)
    .filter((item) => isDeleted(item, newItems))
    .map((c) => ({ ...c, markedForDeletion: true }))
}

function isDeleted<T extends Identifiable>(item: T, existingItems: T[]): boolean {
  if (item.id == null) {
    return false
  }
  return !existingItems.some((nc) => nc.id === item.id)
}

export const SERVER_ERROR_FIELD = 'serverError'
export const SERVER_ERROR_PATH = `root.${SERVER_ERROR_FIELD}` as const

export async function handleFormMutation<Result, FormState extends FieldValues>(
  // Any is appropriate in the next line because the arguments the types refer to are never accessed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: MutationActionCreatorResult<MutationDefinition<unknown, any, any, Result>>,
  setError: UseFormSetError<FormState>,
): Promise<Result> {
  try {
    return await mutation.unwrap()
  } catch (error) {
    if (error instanceof ApiError) {
      setError(SERVER_ERROR_PATH, {
        type: error.status?.toString(),
        message: error.message,
      })
    } else {
      console.error('Unknown form mutation error', error)
    }
    throw error
  }
}
